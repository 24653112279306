<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-table stripe hoverFlat ref="table" :data="history" :noDataText="getDataOngoing?$t('Loading'):$t('NoRecordsFounded')">
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      <vx-card class="user-list-filters mb-8 mt-6">
      <div class="vx-row ">
        <div class="vx-col md:w-1/4 sm:w-1/4 w-full ">
          <vs-input v-model="searchString" @keyup="textSearch($event)" :label="$t('SearchContact')" class="w-full"/>
        </div>
        <div class="vx-col md:w-1/4 sm:w-2/4 w-full">
          <label class="text-sm opacity-75">Data</label>
          <vs-select
            :icon="!datePickerValue?'icon-calendar':'icon-x'"
            icon-pack="feather"
            class="no-icon-border cursor-pointer w-full"
            :placeholder="timeOptionCustomText"
            size="small"
            v-model="dateFilterParam"
          >
              <vs-select-item
                :value="null"
                :text="datePickerValue?$t('Clear'):$t('All')"
                class="w-full"
              />
              <vs-select-item
                :value="timeOptionCustomValue"
                :text="timeOptionCustomText"
                @click="popupDatePicker = true"
                class="w-full"
              />
          </vs-select>
        </div>
        <!-- date-picker -->
        <vs-popup
          class="holamundo"
          :title="timeOptionCustomText"
          :active.sync="popupDatePicker"
        >
          <!-- popup body -->
          <date-picker
            ref="customDatePicker"
            :key="customDatePickerKey"
            v-model="datePickerValue"
            :default-value="datePickerValueDefault"
            :disabled-date="disabledDate"
            format="DD-MM-YYYY"
            range
            inline
            confirm
            @change="handleConfirmDatePicker"
          />
        </vs-popup>
       
      </div>
    </vx-card>
        <vs-divider />
        <div class="flex flex-wrap-reverse items-center">
          <vs-button
            class="border-none p-2"
            type="border"
            size="large"
            color="primary"
            icon-pack="feather"
            icon="icon-arrow-left"
            :to="{name: 'appstore'}"
          ></vs-button>
          <h4>{{ $t("AppStoreHistory") }}</h4>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="createdAt">Data</vs-th>
        <vs-th sort-key="leadDescription">Descrição do Contato</vs-th>
        <vs-th sort-key="appName">Canal</vs-th>
        <vs-th sort-key="triggerType">Tipo de envio</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th>Retorno</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :state="indextr%1?'data-list-container':null" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="font-medium">{{ toDate(tr.createdAt) }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.leadDescription }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.appName }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.triggerType && tr.triggerType === 10 ? $t('Closing') : $t('Opening')}}</p>
            </vs-td>

            <vs-td>
              <vs-chip
                :color="getStatusColor(tr.status)"
                class="product-order-status"
                >{{ getStatusDescription(tr.status) }}</vs-chip
              >
            </vs-td>

            <vs-td>
              <p class="product-price">
                <feather-icon
                  v-if="tr.status==2||tr.status==3||tr.status==5"
                  icon="EyeIcon"
                  svgClasses=" w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="showReturnData(indextr)"
                />
                <span
                  v-else
                >-</span>
              </p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      v-if="history.length > 0"
      class="mt-4"
      :total="totalPages"
      v-model="currentPage"
      @click="currentPage++"
    />
    <vs-popup
      class="holamundo"
      :title="$t('appHistoryReturnTitle')"
      :active.sync="showPopup"
    >
      <h5 v-if="returnCodeCurrentData.length > 0">
        {{ $t("ReturnCode") }} - {{ returnCodeCurrentData }}
      </h5>
      <p class="mt-2">
        
      <pre v-if="returnCurrentData.length > 0" style="background-color:#eee"><code>{{ returnCurrentData }}</code></pre>
      </p>
      <p
        v-if="
          returnCurrentData.length === 0 && returnCodeCurrentData.length === 0
        "
      >{{$t('NoRecordsFounded')}}</p>
    </vs-popup>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
export default {
  components:{
    DatePicker,
  },
  data() {
    return {
      getDataOngoing: false,
      searchTimer: undefined,
      searchString:"",
      timeOptionCustomValue: this.$t('ChooseDate'),
      timeOptionCustomText: this.$t('ChooseDate'),
      customDatePickerKey: 0,
      popupDatePicker: false,
      dateFilterParam: null,
      datePickerValueDefault: [
        new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), // 90 days ago
        new Date(), // today's date
      ],
      datePickerValue: null,

      showPopup: false,
      history: [],
      historyCount: 0,
      maxItensPerPage: 10,
      currentPage: 0,
      isMounted: false,
      returnCurrentData: "",
      returnCodeCurrentData: "",
    };
  },
  computed:{
    disabledDate() {
      const today = new Date();
      return (date) => date > today;
    },
    totalPages(){
      return Math.ceil(this.historyCount/this.maxItensPerPage);
    }
  },
  watch: {
    popupDatePicker: function (val){
      if(!this.datePickerValue&&!val)this.dateFilterParam=null;
    },
    dateFilterParam: function () {
      if (this.dateFilterParam === this.timeOptionCustomText) {
        this.popupDatePicker = true;
        return;
      }
      if(!this.dateFilterParam) {
        this.datePickerValue = null;
        this.timeOptionCustomValue= this.$t('ChooseDate');
        this.timeOptionCustomText= this.$t('ChooseDate');
      }
      this.currentPage=0;
      this.historyCount = 0;
      this.history = [];
      this.getData(this.dateFilterParam);
    },
    currentPage: function () {
      this.getData();
    },
    showPopup: function (val) {
      if (!val) {
        this.returnCurrentData = "";
        this.returnCodeCurrentData = "";
      }
    },
  },
  methods: {
    clearDataPicker(){
      this.timeOptionCustomValue = this.$t('ChooseDate'); 
      this.timeOptionCustomText = this.$t('ChooseDate'); 
      this.dateFilterParam = null;
      this.datePickerValue = null;
    },
    textSearch(){
      clearTimeout(this.timer)
      const thisIns = this;
      this.timer = setTimeout(() => {
        thisIns.historyCount = 0;
        thisIns.history = [];
        thisIns.getData();
      }, 1000);
    },
    handleConfirmDatePicker() {
      this.popupDatePicker = false;
      this.customDatePickerKey += 1;
      this.$nextTick(() => {
        const customDatePickerText = this.$refs.customDatePicker.text;
        this.timeOptionCustomText = customDatePickerText;
      });
      this.currentPage=0;
      this.historyCount = 0;
      this.history = [];
      this.getData(this.timeOptionCustomText);
    },
    toDate(val){
      return new Date(val).toLocaleString("pt-br");
    },
    showReturnData(id) {
      this.returnCodeCurrentData = this.history[id].responseCode || "";
      this.returnCurrentData = this.history[id].responseDescription || "";
      this.showPopup = true;
    },
    getStatusColor(status) {
      if (status == 1) return "warning";
      if (status == 2) return "danger";
      if (status == 3) return "success";
      if (status == 4) return "status-cancelled";
      if (status == 5) return "#5cbbf6";
      return "primary";
    },
    getStatusDescription(status) {
      if (status == 1) return this.$t("ongoing");
      if (status == 2) return this.$t("error");
      if (status == 3) return this.$t("success");
      if (status == 4) return this.$t("cancelled");
      if (status == 5) return this.$t("finished");
      return "-";
    },
    formatData(data) {
      // formats data received from API
      let formattedData = data.map((item) => {
        const fields = item[0] || {};
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] =
            Number(fields[key].integerValue) ||
            Number(fields[key].doubleValue) ||
            fields[key].stringValue;
        }
        return obj;
      });
      return formattedData;
    },
    getData() {
      this.getDataOngoing=true;
      const thisIns = this;
      this.$http
        .get("/g/acc/apps/history", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            page: this.currentPage>0?this.currentPage-1:0,
            search: this.searchString,
            dateRange: this.datePickerValue
          },
        })
        .then(function (response) {
          thisIns.getDataOngoing=false;
          thisIns.historyCount = response.data.data.count;
          thisIns.history = response.data.data.documents;
        })
        .catch(function (error) {
          thisIns.getDataOngoing=false;
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        });
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.data-list-container{
  background-color: #626262;
}
.vs-chip-status-cancelled {
  background-color: #626262;
}
.drp-selected {
  display: none !important;
}
.off {
  color: #62626273 !important;
}
.table-condensed th,
.table-condensed td {
  color: #626262;
  text-align: center;
}
.table-condensed th {
  padding: 10px;
}
.daterangepicker {
  position: absolute;
  z-index: 800;
  border: 1px solid #ccc;
  right: 0;
  width: min-content;
  background-color: white;
}
.drp-buttons {
  display: flex;
  justify-content: flex-end;
}
.month {
  padding: 18px 0 10px 30px !important;
}
.monthselect {
  padding: 0 0.75rem 0 0;
  color: #626262;
  margin-right: 14px;
  border: none;
  font-size: 16px;
}
.yearselect {
  padding: 0 0.75rem 0 0;
  color: #626262;
  border: none;
  width: 40%;
  font-size: 16px;
}
.table-condensed th > .row {
  display: flex;
  justify-content: center;
}
.end-date,
.start-date {
  background-color: rgba(var(--vs-primary), 1) !important;
  color: white;
}
.in-range {
  background-color: rgba(var(--vs-primary), 0.2);
}
.cancelBtn {
  border: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.61);
  border-radius: 5px;
  margin: 1rem;
}
.applyBtn {
  background-color: rgba(var(--vs-primary), 1);
  border: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  margin: 1rem;
  margin: 1rem 1rem 1rem 0;
}
.vs-chip-success {
  background: rgba(var(--vs-success), 0.15);
  color: rgba(var(--vs-success), 1) !important;
  font-weight: 500;
}
.vs-chip-warning {
  background: rgba(var(--vs-warning), 0.15);
  color: rgba(var(--vs-warning), 1) !important;
  font-weight: 500;
}
.vs-chip-danger {
  background: rgba(var(--vs-danger), 0.15);
  color: rgba(var(--vs-danger), 1) !important;
  font-weight: 500;
}
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */

.vs-button-border.isActive span {
  color: #7367f0 !important;
}

a.isActive {
  border-bottom: 2px solid #7367f0;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}
.daterangepicker th.month {
  width: auto;
}
.monthselect[data-v-155e681b] {
  border: none;
}
.daterangepicker .ranges {
  text-align: left;
  margin: 0;
  width: 100%;
}
.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}
.daterangepicker .ranges li:hover {
  background-color: #eee;
  color: #000;
}
.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}
.daterangepicker .monthselect,
.daterangepicker .yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
  width: calc(50% - 1rem);
}
.daterangepicker .monthselect {
  margin-right: 1rem;
}
</style>
